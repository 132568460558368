import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateCoacheTagsAction } from "../../../../redux/coaches.reducer";
import { InputText } from "primereact/inputtext";

const init_create = {
  tags: [],
};

function UpdateTags(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.CoacheReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateCoacheTagsAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Trainer Tags</label>
          <MultiTags
            name="tags"
            value={item.tags}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </Dialog>
  );
}

export const MultiTags = ({ onChange = () => {}, name = "", value = [] }) => {
  const [tags, setTags] = useState(["", "", ""]);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const AddTag = () => {
    setTags([...tags, ""]);
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    tags[index] = value;
    setTags([...tags]);
    onChange({ target: { name, value: tags } });
  };

  const RemoveTag = (index) => {
    tags.splice(index, 1);
    setTags([...tags]);
    onChange({ target: { name, value: tags } });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {tags.map((tag, index) => {
        return (
          <span
            className="p-input-icon-right"
            style={{ width: "24%", margin: "5px 0.5% 5px 0.5%" }}
          >
            <i
              className="pi pi-times"
              style={{ cursor: "pointer" }}
              onClick={RemoveTag}
            />
            <InputText
              key={index}
              name="title"
              value={tag}
              placeholder={`Tag ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />
          </span>
        );
      })}
      <Button
        label="Add Tag"
        icon="pi pi-plus"
        className="p-button-filled"
        style={{ width: "24%", margin: "5px 0.5% 5px 0.5%" }}
        onClick={AddTag}
      />
    </div>
  );
};

export default UpdateTags;
