import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Uploader from "../../../../MyComponents/Buttons/Uploader";

import { useDispatch, useSelector } from "react-redux";
import { CreatePartnerValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { AddPartnerAction } from "../../../../redux/partners.reducer";
import { Dropdown } from "primereact/dropdown";

const init_create = {
  img: "",
  name: "",
  link: "",
  joinDate: "",
  published: "published",
};

function AddDialog(props) {
  const { open, handleClose, title = "Add Partner" } = props;
  const payload = useSelector((state) => state.PartnersReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreatePartnerValidation(item)) {
      dispatch(AddPartnerAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Partner Name*</label>
          <InputText name="name" value={item.name} onChange={handleChange} />
        </div>

        <div className="field col-12 md:col-12">
          <label>Partner Link*</label>
          <InputText name="link" value={item.link} onChange={handleChange} />
        </div>

        <div className="field col-12 md:col-12">
          <label>Partner Join Date*</label>
          <InputText
            type="date"
            name="joinDate"
            value={item.joinDate}
            onChange={handleChange}
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Published*</label>
          <Dropdown
            value={item.published}
            onChange={(e) => setItem({ ...item, published: e.value })}
            options={["published", "unpublished"]}
            placeholder="Select"
            style={{ width: "100%" }}
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Upload Partner Image*</label>
          <Uploader
            label="Upload Partner Image"
            name="img"
            value={item.img}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AddDialog;
