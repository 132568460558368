import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";
import AddDialgog from "./popups/AddDialgog";
import UpdateDialog from "./popups/UpdateDialog";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetPartnersAction } from "../../../redux/partners.reducer";
import ImgBack from "../../../MyComponents/Image/ImgBack";
import { Tag } from "primereact/tag";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openU, handleOpenU, handleCloseU] = usePopup(row);
  const [openD, handleOpenD, handleCloseD] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## UPDATE ################## */}
      <Button
        icon="pi pi-pencil"
        tooltip={`Update Partner ${row.name}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenU()}
      />

      {openU && (
        <UpdateDialog
          open={openU}
          handleClose={handleCloseU}
          value={row}
          title={`Update Partner ${row.name}`}
        />
      )}

      {/* ################## DELETE ################## */}

      <Button
        icon="pi pi-trash"
        tooltip={`Delete Partner ${row.name}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => handleOpenD()}
      />

      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete Partner ${row.name}`}
        />
      )}
    </div>
  );
};

const GestPartners = () => {
  const partners = useSelector((state) => state.PartnersReducer.data);
  const dispatch = useDispatch();

  console.log(partners);

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [openAdd, handleOpenAdd, handleCloseAdd] = usePopup("add");
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetPartnersAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Partners</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
          <Button
            label="New"
            icon="pi pi-plus"
            tooltip="Add Partner"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-primary mr-2"
            onClick={handleOpenAdd}
          />
          {openAdd && (
            <AddDialgog
              open={openAdd}
              handleClose={handleCloseAdd}
              title="Add New Partner"
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const Col1 = (row) => {
    return (
      <ImgBack
        src={row.img}
        alt={row.name}
        className="img-fluid"
        style={{ height: "40px" }}
      />
    );
  };

  const PartnerName = (row) => {
    return `${row?.name}`;
  };

  const JoinDate = (row) => {
    return `${new Date(row?.joinDate).toLocaleDateString()}`;
  };

  const Published = (row) => {
    const types = {
      published: (
        <Tag className="mr-2 mt-1" severity="success" value="Published" />
      ),
      unpublished: (
        <Tag className="mr-2 mt-1" severity="danger" value="Not Published" />
      ),
    };

    return types[row?.published];
  };

  const State = (row) => {
    //<Tag className="mr-2" severity="info" value="Info" />
    const states = {
      BLOCKED: <Tag className="mr-2 mt-1" severity="danger" value="BLOCKED" />,
      NORMAL: <Tag className="mr-2 mt-1" severity="success" value="NORMAL" />,
      INDANGER: (
        <Tag className="mr-2 mt-1" severity="warning" value="IN DANGER" />
      ),
      "PENDING-V": <Tag className="mr-2 mt-1" value="PENDING-V" />,
    };

    return states[row?.State];
  };

  const Actions = (row) => {
    return <ActionBodyTemplate row={row} />;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...partners]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Partners found."
            header={
              <FilterComp
                filter_name="Filter Partners"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            <Column field="Image" header="Image" body={Col1} />

            <Column
              field="Partner Name"
              header="Partner Name"
              body={PartnerName}
            />

            <Column field="Join Date" header="Join Date" body={JoinDate} />
            <Column field="Published" header="Published" body={Published} />
            <Column header="Actions" body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestPartners;
