import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Uploader from "../../../../MyComponents/Buttons/Uploader";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateNutriAction } from "../../../../redux/nutritionists.reducer";
import { GeneratePass } from "../../../../functions/GeneratePass";
import { Dropdown } from "primereact/dropdown";
import { makeDate2 } from "../../../../functions/DateFunctions";

const init_create = {
  fullname: "",
  email: "",
  password: GeneratePass(),
  phoneNumber: "",
  MainImg: "",
  speciality: "",
  birthDate: "",
  Location: "",
  sex: "",
  VideoLink: "",
  About: "",
  // CoverProfileImage: "",
  NbYearsExp: "",
  DateStartExp: "",
};

function UpdateDialgog(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.NutriReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateNutriAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Nutritionist Fullname*</label>
          <InputText
            name="fullname"
            value={item.fullname}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Email*</label>
          <InputText
            name="email"
            value={item.email}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Speciality*</label>
          <InputText
            name="speciality"
            value={item.speciality}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Phone Number*</label>
          <InputText
            name="phoneNumber"
            value={item.phoneNumber}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Birth Date*</label>
          <InputText
            type="date"
            name="birthDate"
            value={makeDate2(item.birthDate)}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Location*</label>
          <InputText
            name="Location"
            value={item.Location}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Gender*</label>
          <Dropdown
            value={item.sex}
            onChange={(e) => setItem({ ...item, sex: e.value })}
            options={["MEN", "WOMEN", "OTHER"]}
            placeholder="Select"
            style={{ width: "100%" }}
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist Date Start*</label>
          <InputText
            type="date"
            name="DateStartExp"
            value={makeDate2(item.DateStartExp)}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Nutritionist About*</label>
          <InputTextarea
            rows={4}
            name="About"
            value={item.About}
            onChange={handleChange}
            required
          />
        </div>

        <div className="field col-12 md:col-12">
          <label>Upload Main Image*</label>
          <Uploader
            label="Upload Nutritionist Profile Image"
            name="MainImg"
            value={item.MainImg}
            onChange={handleChange}
            required
          />
        </div>

        {/* <div className="field col-12 md:col-12">
          <label>Upload Cover Image*</label>
          <Uploader
            label="Upload Nutritionist Cover Image"
            name="CoverProfileImage"
            value={item.CoverProfileImage}
            onChange={handleChange}
            required
          />
        </div> */}
      </div>
    </Dialog>
  );
}

export default UpdateDialgog;
