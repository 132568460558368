// https://fontawesome.com/search?o=r&m=free

export const menu = [
  {
    label: "Dashboard",
    items: [
      {
        label: "Overview",
        icon: "fa-solid fa-chart-line",
        to: "/dashboard/",
      },
      {
        label: "Admins & Staff",
        icon: "fa-solid fa-user-tie",
        to: "/dashboard/admins",
      },
      {
        label: "Users",
        icon: "fa-solid fa-users",
        to: "/dashboard/users",
      },
      {
        label: "Manage Trainers",
        to: "/dashboard/coaches",
        icon: "fa-solid fa-chalkboard-user",
        items: [
          {
            label: "Trainer Services",
            icon: "fa-solid fa-hand-holding-dollar",
            to: "/dashboard/sports",
          },
          {
            label: "Trainer Approches",
            icon: "fa-solid fa-person-burst",
            to: "/dashboard/services",
          },
          {
            label: "Consult Mode",
            icon: "fa-solid fa-user-group",
            to: "/dashboard/nut-consult-mode",
          },
        ],
      },
      {
        label: "Manage Nutritionists",
        icon: "fa-solid fa-user-doctor",
        to: "/dashboard/nutritionists",
        items: [
          {
            label: "Nutritionist Services",
            icon: "fa-solid fa-hand-holding-dollar",
            to: "/dashboard/nut-services",
          },
          {
            label: "Nutritionist Approches",
            icon: "fa-solid fa-wheat-awn-circle-exclamation",
            to: "/dashboard/nut-approches",
          },
          {
            label: "Consult Mode",
            icon: "fa-solid fa-user-group",
            to: "/dashboard/nut-consult-mode",
          },
        ],
      },
      {
        label: "Trenapedia Content",
        icon: "fa-solid fa-pen-clip",
        to: "/dashboard/blogs",
      },
      // {
      //   label: "Analytics & Insights",
      //   icon: "fa-solid fa-chart-simple",
      //   to: "/dashboard/analytics-insights",
      // },
      {
        label: "Partners & Sponsors",
        icon: "fa-solid fa-handshake",
        to: "/dashboard/partners-sponsors",
      },
      {
        label: "Financials",
        icon: "fa-solid fa-hand-holding-dollar",
        to: "/dashboard/financials",
      },
      {
        label: "Support & Feedback",
        icon: "fa-solid fa-headset",
        to: "/dashboard/support-feedback",
      },
    ],
  },

  // {
  //   label: "Website Content",
  //   items: [
  //     {
  //       label: "Conversations",
  //       icon: "pi pi-fw pi-comments",
  //       to: "/dashboard/conversation",
  //     },
  //     {
  //       label: "Mailing",
  //       icon: "pi pi-fw pi-envelope",
  //       to: "/dashboard/mailing",
  //     },
  //   ],
  // },
];

//coach services => coach approach  =>
//coach sports> coach services

//coach consult mode
// change formulaire
// coach => trainer
