import React from "react";
import { Avatar } from "primereact/avatar";
import colors from "../../theme/AvatarColors";
import AvatarColors from "../../theme/AvatarColors";

function getColorForCharacter(char) {
  // Convert character to uppercase to make it case-insensitive
  const upperChar = char?.[0]?.toUpperCase();

  // Check character ranges
  if ("ABCDE".includes(upperChar)) {
    return AvatarColors[0];
  } else if ("FGHIJ".includes(upperChar)) {
    return AvatarColors[1];
  } else if ("KLMNO".includes(upperChar)) {
    return AvatarColors[2];
  } else if ("PQRST".includes(upperChar)) {
    return AvatarColors[3];
  } else if ("WUXYZ".includes(upperChar)) {
    return AvatarColors[4];
  }

  // Default color for unsupported or special characters
  return AvatarColors[0]; // Black text on white background
}
const AvatarLabel = ({ label, circle }) => {
  let { txt, bg } = getColorForCharacter(label);
  return (
    <Avatar
      size="large"
      label={label}
      shape={circle ? "circle" : "square"}
      style={{ backgroundColor: bg, color: txt }}
    />
  );
};

const AvatarImg = ({ src, circle }) => {
  return (
    <Avatar
      size="large"
      image={src}
      shape={circle ? "circle" : "square"}
      style={{ objectFit: "cover" }}
    />
  );
};

function AvatarComponent({ name = " ", lastname = " ", src, circle = false }) {
  return src && src.length > 4 ? (
    <AvatarImg src={src} circle={circle} />
  ) : (
    <AvatarLabel
      label={`${name?.[0]?.toUpperCase()}${lastname?.[0]?.toUpperCase() || ""}`}
      circle={circle}
    />
  );
}

export default AvatarComponent;
