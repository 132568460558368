import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";
import AddDialgog from "./popups/AddDialgog";
import UpdateDialog from "./popups/UpdateDialog";
import UpdateLinks from "./popups/UpdateLinks";
import UpdateAproches from "./popups/UpdateAproches";
import UpdateServices from "./popups/UpdateServices";
import UpdateConsultMode from "./popups/UpdateConsultMode";
import UpdateTags from "./popups/UpdateTags";
import UpdateImages from "./popups/UpdateImages";
import UpdateCertifs from "./popups/UpdateCertifs";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetNutriAction } from "../../../redux/nutritionists.reducer";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import { Tag } from "primereact/tag";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openU, handleOpenU, handleCloseU] = usePopup(row);
  const [openD, handleOpenD, handleCloseD] = usePopup(row);
  const [openL, handleOpenL, handleCloseL] = usePopup(row);
  const [openCM, handleOpenCM, handleCloseCM] = usePopup(row);
  const [openT, handleOpenT, handleCloseT] = usePopup(row);
  const [openI, handleOpenI, handleCloseI] = usePopup(row);
  const [openC, handleOpenC, handleCloseC] = usePopup(row);
  const [openA, handleOpenA, handleCloseA] = usePopup(row);
  const [openSp, handleOpenSp, handleCloseSp] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## SHOW ################## */}
      <Button
        icon="pi pi-eye"
        tooltip={`Show ${row.fullname} Details`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-success mr-0"
        onClick={() => handleOpenU()}
      />

      {/* {openU && (
        <UpdateDialog
          open={openU}
          handleClose={handleCloseU}
          value={row}
          title={`Update General Info`}
        />
      )} */}

      {/* ################## UPDATE GENERAL ################## */}
      <Button
        icon="pi pi-pencil"
        tooltip={`Update General Info`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenU()}
      />

      {openU && (
        <UpdateDialog
          open={openU}
          handleClose={handleCloseU}
          value={row}
          title={`Update General Info`}
        />
      )}

      {/* ################## UPDATE CERTIFS ################## */}
      <Button
        icon="pi pi-id-card"
        tooltip={`Update Certifs`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenC()}
      />

      {openC && (
        <UpdateCertifs
          open={openC}
          handleClose={handleCloseC}
          value={row}
          title={`Update Certifs`}
        />
      )}

      {/* ################## UPDATE IMAGES ################## */}
      <Button
        icon="pi pi-images"
        tooltip={`Update Images`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenI()}
      />

      {openI && (
        <UpdateImages
          open={openI}
          handleClose={handleCloseI}
          value={row}
          title={`Update Images`}
        />
      )}

      {/* ################## UPDATE TAGS ################## */}
      <Button
        icon="pi pi-hashtag"
        tooltip={`Update Tags`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenT()}
      />

      {openT && (
        <UpdateTags
          open={openT}
          handleClose={handleCloseT}
          value={row}
          title={`Update Tags`}
        />
      )}

      {/* ################## UPDATE Consultation FIELDS ################## */}
      <Button
        icon="fa-solid fa-stethoscope"
        tooltip={`Update Consultation Mode`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenCM()}
      />

      {openCM && (
        <UpdateConsultMode
          open={openCM}
          handleClose={handleCloseCM}
          value={row}
          title={`Update Sport Fields`}
        />
      )}

      {/* ################## UPDATE APROCHES ################## */}
      <Button
        icon="fa-solid fa-wheat-awn-circle-exclamation"
        tooltip={`Update Nutritionist Aproches`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenA()}
      />

      {openA && (
        <UpdateAproches
          open={openA}
          handleClose={handleCloseA}
          value={row}
          title={`Update Nutritionist Aproches`}
        />
      )}

      {/* ################## UPDATE SERVICES ################## */}
      <Button
        icon="fa-solid fa-hand-holding-dollar"
        tooltip={`Update Services`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenSp()}
      />

      {openSp && (
        <UpdateServices
          open={openSp}
          handleClose={handleCloseSp}
          value={row}
          title={`Update Services`}
        />
      )}

      {/* ################## UPDATE LINKS ################## */}
      <Button
        icon="pi pi-link"
        tooltip={`Update Links`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenL()}
      />

      {openL && (
        <UpdateLinks
          open={openL}
          handleClose={handleCloseL}
          value={row}
          title={`Update Links`}
        />
      )}

      {/* ################## DELETE ################## */}

      <Button
        icon="pi pi-trash"
        tooltip={`Delete nutritionist ${row.fullname}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => handleOpenD()}
      />

      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete nutritionist ${row.fullname}`}
        />
      )}
    </div>
  );
};

const GestNutritionists = () => {
  const data = useSelector((state) => state.NutriReducer.data);
  const dispatch = useDispatch();

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [openAdd, handleOpenAdd, handleCloseAdd] = usePopup("add");
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetNutriAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Nutritionists</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
          <Button
            label="New"
            icon="pi pi-plus"
            tooltip="Add nutritionist"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-primary mr-2"
            onClick={handleOpenAdd}
          />
          {openAdd && (
            <AddDialgog
              open={openAdd}
              handleClose={handleCloseAdd}
              title="Add New nutritionist"
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const AvatarNut = (row) => {
    return (
      <div className=" flex ">
        <AvatarComponent
          src={null}
          name={row?.fullname?.[0]}
          lastname={row.fullname?.[1]}
        />
        <div className=" flex flex-column justify-content-center ml-2  ">
          <span className=" font-semibold ">{`${row.fullname}`}</span>
          <span className=" text-600 ">{row.email}</span>
        </div>
      </div>
    );
  };

  const Location = (row) => {
    return `${row?.Location}`;
  };

  const Sex = (row) => {
    return row?.sex;
  };

  const Phone = (row) => {
    return row?.phoneNumber;
  };

  const Speciality = (row) => {
    return row?.speciality;
  };

  const NbYearsExp = (row) => {
    return new Date().getFullYear() - new Date(row?.DateStartExp).getFullYear();
  };

  const State = (row) => {
    //<Tag className="mr-2" severity="info" value="Info" />
    const states = {
      BLOCKED: <Tag className="mr-2 mt-1" severity="danger" value="BLOCKED" />,
      NORMAL: <Tag className="mr-2 mt-1" severity="success" value="NORMAL" />,
      INDANGER: (
        <Tag className="mr-2 mt-1" severity="warning" value="IN DANGER" />
      ),
      "PENDING-V": <Tag className="mr-2 mt-1" value="PENDING-V" />,
    };

    return states[row?.State];
  };

  const Actions = (row) => {
    return <ActionBodyTemplate row={row} />;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...data]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No nutritionist found."
            header={
              <FilterComp
                filter_name="Filter nutritionists"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            <Column header="Nutritionist" body={AvatarNut} />
            <Column field="Location" header="Location" body={Location} />
            <Column field="Speciality" header="Speciality" body={Speciality} />
            <Column field="Gender" header="Gender" body={Sex} />
            <Column field="Phone" header="Phone" body={Phone} />
            <Column field="Experience" header="Experience" body={NbYearsExp} />
            <Column field="State" header="State" body={State} />
            <Column header="Actions" style={{ width: "10%" }} body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestNutritionists;
