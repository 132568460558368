import React from "react";
import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";

// Utility function to get the last N months as an array of "YYYY/MM"
function getLastNMonths(n) {
  const months = [];
  const currentDate = new Date();

  for (let i = 0; i < n; i++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const yearMonth = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}`;
    months.push(yearMonth);
  }

  return months.reverse(); // Reverse to ensure chronological order
}

// Group data by month for a specific user type
function groupSingleTypeByMonth(data, userType) {
  const result = {};

  data.forEach((user) => {
    if (user.type === userType) {
      const createdDate = new Date(user.createdAt);
      const yearMonth = `${createdDate.getFullYear()}/${String(
        createdDate.getMonth() + 1
      ).padStart(2, "0")}`;

      // Initialize the month group if it doesn't exist
      if (!result[yearMonth]) {
        result[yearMonth] = 0;
      }

      // Increment the count for this month
      result[yearMonth]++;
    }
  });

  return result;
}

// Align grouped data with a predefined array of labels
function alignDataWithLabels(labels, groupedData) {
  return labels.map((label) => groupedData[label] || 0);
}

// Generate aligned chart data for a specific user type
function generateAlignedChartData(data, userType, label, color, allLabels) {
  const groupedData = groupSingleTypeByMonth(data, userType);
  const alignedData = alignDataWithLabels(allLabels, groupedData);

  return {
    label,
    data: alignedData,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    tension: 0.4,
  };
}

// Chart options for light theme
const light = {
  plugins: {
    legend: {
      labels: {
        color: "#495057",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
};

// Main component
function ChartsOverview() {
  const users = useSelector((state) => state.UsersReducer.users);
  const coaches = useSelector((state) => state.CoacheReducer.coaches);
  const nutri = useSelector((state) => state.NutriReducer.data);

  // Generate the last 12 months
  const last12Months = getLastNMonths(12);

  // Generate aligned datasets
  const coachesDataset = generateAlignedChartData(
    coaches,
    "TRAINER",
    "Trainers",
    "#FF3C33",
    last12Months
  );
  const nutritionistsDataset = generateAlignedChartData(
    nutri,
    "NUTRITIONIST",
    "Nutritionists",
    "#23C55F",
    last12Months
  );
  const usersDataset = generateAlignedChartData(
    users,
    "USER",
    "Users",
    "#2196F3",
    last12Months
  );

  // Combine datasets for the chart
  const lineData = {
    labels: last12Months,
    datasets: [coachesDataset, nutritionistsDataset, usersDataset],
  };

  return (
    <div className="card">
      <h5>Joining Overview</h5>
      <Chart type="line" data={lineData} options={light} />
    </div>
  );
}

export default ChartsOverview;
