import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersAction } from "../../../redux/users.reducer";
import { GetCoachesAction } from "../../../redux/coaches.reducer";
import { GetNutriAction } from "../../../redux/nutritionists.reducer";

function HeadCards() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.UsersReducer.users);
  const coaches = useSelector((state) => state.CoacheReducer.coaches);
  const nutri = useSelector((state) => state.NutriReducer.data);

  function getNewUsersThisMonth(datas) {
    const currentMonth = new Date().getMonth(); // Get the current month (0-11)
    const currentYear = new Date().getFullYear(); // Get the current year

    return datas.filter((user) => {
      const createdDate = new Date(user.createdAt);
      return (
        createdDate.getMonth() === currentMonth &&
        createdDate.getFullYear() === currentYear
      );
    });
  }

  useEffect(() => {
    dispatch(GetUsersAction());
    dispatch(GetCoachesAction());
    dispatch(GetNutriAction());
  }, []);

  return (
    <>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Users</span>
              <div className="text-900 font-medium text-xl">
                {users?.length} Users in total
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="fa-solid fa-users text-blue-500 text-md" />
            </div>
          </div>
          <span className="text-blue-500 font-medium">
            {getNewUsersThisMonth(users)?.length} new{" "}
          </span>
          <span className="text-500"> in this month</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Trainers</span>
              <div className="text-900 font-medium text-xl">
                {coaches?.length} Trainers in total
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-red-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="fa-solid fa-chalkboard-user text-red-500 text-md" />
            </div>
          </div>
          <span className="text-red-500 font-medium">
            {getNewUsersThisMonth(coaches)?.length} new{" "}
          </span>
          <span className="text-500"> in this month</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Nutritionists
              </span>
              <div className="text-900 font-medium text-xl">
                {nutri?.length} Nutritionists in total
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-green-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="fa-solid fa-user-doctor text-green-500 text-md" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {getNewUsersThisMonth(nutri)?.length} new{" "}
          </span>
          <span className="text-500">in this month</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Subscriptions
              </span>
              <div className="text-900 font-medium text-xl">
                6 Subscriptions in total
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="fa-solid fa-hand-holding-dollar text-orange-500 text-md" />
            </div>
          </div>
          <span className="text-orange-500 font-medium">0 </span>
          <span className="text-500">in this month</span>
        </div>
      </div>
    </>
  );
}

export default HeadCards;
