import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_partners: "update_partners",
  payload: "payload_partners",
  all: "all_partners",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  data: [],
};

// ################################# REDUCERS #################################

export const PartnersReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_partners:
      return { ...state, data: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddPartnerAction = (partner, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.post(`/api/partners/create`, {
        ...partner,
      });

      dispatch(GetPartnersAction());

      toast.success("Partner added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetPartnersAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(`/api/partners/get-all`);

      console.log(response?.data);

      dispatch({
        type: keys.all,
        value: {
          payload: false,
          data: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdatePartnerAction = (partner, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.put(`/api/partners/update/${partner._id}`, {
        ...partner,
      });

      console.log(response?.data);

      dispatch(GetPartnersAction());
      toast.success("Partner updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeletePartnerAction = (partner, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.delete(
        `/api/partners/delete/${partner._id}`
      );

      console.log(response?.data);

      dispatch(GetPartnersAction());
      toast.success("Partner deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  AddPartnerAction,
  GetPartnersAction,
  UpdatePartnerAction,
  DeletePartnerAction,
};
