import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateCoacheImagesAction } from "../../../../redux/coaches.reducer";
import Uploader from "../../../../MyComponents/Buttons/Uploader";

const init_create = {
  Images: [],
};

function UpdateImages(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.CoacheReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateCoacheImagesAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Trainer Images</label>
          <MultiImages
            name="Images"
            value={item.Images}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </Dialog>
  );
}

export const MultiImages = ({ onChange = () => {}, name = "", value = [] }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(value);
  }, [value]);

  const AddImage = () => {
    setImages([...images, ""]);
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    images[index] = value;
    setImages([...images]);
    onChange({ target: { name, value: images } });
  };

  const RemoveImage = (index) => {
    images.splice(index, 1);
    setImages([...images]);
    onChange({ target: { name, value: images } });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Button
        label="Add Image"
        className="p-button-filled"
        style={{ width: "100%", margin: "5px 0px 20px 0px" }}
        onClick={AddImage}
      />

      {images.map((image, index) => {
        return (
          <span
            className="p-input-icon-right flex "
            style={{
              width: "100%",
              margin: "5px 1% 5px 0% ",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                width: "88%",
              }}
            >
              <Uploader
                key={index}
                name="title"
                label={`Upload Image ${index + 1}`}
                value={image}
                placeholder={`Image ${index + 1}`}
                onChange={(e) => handleChange(e, index)}
              />
            </span>

            <Button
              label=""
              icon="pi pi-times"
              className="p-button-filled mb-2"
              style={{ width: "10%", margin: "0px 0px 0px 0px" }}
              onClick={() => {
                RemoveImage(index);
              }}
            />
          </span>
        );
      })}
    </div>
  );
};

export default UpdateImages;
